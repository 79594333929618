import React from 'react';
import './Programs.css';
import RightArrow from '../../assets/rightArrow.png';
import { programsData } from '../../data/programsData';

const Programs = () => {
    return (
        <div className="programs" id="programs">
            {/* Header start */}
            <div className="programs-header">
                <span className='stroke-text'>Explore our</span>
                <span>Program</span>
                <span className='stroke-text'>to shape you</span>
            </div>
            {/* Header end */}

            {/* Card start */}
            <div className="programs-categories">
                {programsData.map((program) => (
                    <div className="category">
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                        <div className="join-now">
                            <span>Join Now</span>
                            <img src={RightArrow} alt="" />
                        </div>
                    </div>
                ))}
            </div>
            {/* Card end */}
        </div>
    )
}

export default Programs
