import React from 'react';
import Header from '../Header/Header';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter';
import './Hero.css';

const Hero = () => {
    const transition = { type: 'spring', duration: 2 };
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className='hero' id='home'>
            <div className="blur hero-blur"></div>
            {/* Hero Left start */}
            <div className='left-h'>
                <Header />

                {/* the best ad start */}
                <div className='the-best-ad'>
                    <motion.div
                        initial={{ left: mobile ? "160px": "238px" }}
                        whileInView={{left: '8px'}}
                        transition={{...transition, type: 'tween'}}
                    ></motion.div>
                    <span>the best fitness club in the town</span>
                </div>
                {/* the best ad en */}

                {/* Hero Heading start */}
                <div className='hero-text'>
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal Body</span>
                    </div>
                    <div>
                        <span>
                            In here we will help you to shape and build your ideal body and live up your life to fullest.
                        </span>
                    </div>
                </div>
                {/* Hero Heading end */}

                {/* figures start */}
                <div className='figures'>
                    <div>
                        <span><NumberCounter end={140} start={100} delay='4' preFix="+"/></span>
                        <span>EXPERT COACHES</span>
                    </div>
                    <div>
                        <span><NumberCounter end={978} start={800} delay='4' preFix="+"/></span>
                        <span>MEMBERS JOINED</span>
                    </div>
                    <div>
                        <span><NumberCounter end={50} start={0} delay='4' preFix="+"/></span>
                        <span>FITNESS PROGRAMS</span>
                    </div>
                </div>
                {/* figures end */}

                {/* buttons start */}
                <div className='hero-buttons'>
                    <button className='btn'>Get started</button>
                    <button className='btn'>Learn More</button>
                </div>
                {/* buttons end */}
            </div>
            {/* Hero Left end */}

            {/* Hero Right start */}
            <div className='right-h'>
                <button className='btn'>Join Now</button>
                <motion.div
                    initial={{right : "0rem"}}
                    whileInView={{right: "4rem"}}
                    transition={transition}
                    className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span><span>116 bpm</span>
                </motion.div>

                {/* Hero image start */}
                <img src={hero_image} alt="" className="hero-image" />
                <motion.img
                    initial={{ right: "11rem" }}
                    whileInView={{ right: "20rem" }}
                    transition={transition}
                    src={hero_image_back} alt="" className="hero-image-back" />

                {/* Calories start */}
                <motion.div
                    initial={{ right: "37rem" }}
                    whileInView={{ right: "28rem" }}
                    transition={transition}
                    className="calories">
                    <img src={Calories} alt="" />
                    <div>
                        <span>Calories Burned</span>
                        <span>220 Kcal</span>
                    </div>
                </motion.div>
                {/* Calories end */}

                {/* Hero image end */}
            </div>
            {/* Hero Right end */}
        </div>
    )
}

export default Hero
